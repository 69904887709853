import {Controller} from "@hotwired/stimulus";
import insertSubjectSender from "./insert_subject_sender";

export default class extends Controller {
    static targets = ["iframe", "html", "popover", "textarea", "hintContainer", "count", "blank", "hintTemplate", "templateSelect"];
    static values  = {
        sender: String,
        subject: String,
    };

    connect() {
        this.iframeTarget.contentWindow.document.addEventListener("mouseover", (event) => this.highlighter(event));
        this.iframeTarget.contentWindow.document.addEventListener("click", (event) => this.addHint(event));
        this.popoverTarget.addEventListener("keydown", (event) => this.handleKeydown(event));
        this.iframeTarget.contentWindow.document.querySelectorAll("*").forEach(element => {
            element.style.cursor = "copy";
        });

        const create = document.querySelector("#createSimulation");
        create.addEventListener("click", () => this._sendForm());

        this._hints = {};
    }

    highlighter(event) {
        // Remove border from previously selected element
        if (this.selectedElement && !this.selectedElement.dataset.hint) {
            this.selectedElement.style.outline       = "";
            this.selectedElement.style.outlineOffset = "";
            this.selectedElement.style.transition    = "";
        }

        // if selected element is html or body, return
        if (event.target.tagName.toLowerCase() === "html" || event.target.tagName.toLowerCase() === "body") {
            return;
        }

        // Get the selected element using the mouse position and add border
        let [x, y]           = [event.clientX, event.clientY];
        this.selectedElement = this.iframeTarget.contentWindow.document.elementFromPoint(x, y);

        if (this.selectedElement.dataset.hint) {
            return;
        }

        this.selectedElement.style.outline       = "dashed #1185E5";
        this.selectedElement.style.outlineOffset = "0.25rem";
        this.selectedElement.style.transition    = "outline 0.1s ease-in-out";
    }

    handleKeydown(event) {
        if (event.key === "Escape") {
            this.popoverTarget.hidePopover();
            this.textareaTarget.value = "";
        }

        if (event.key === "Enter") {
            if (this.textareaTarget.value.trimStart() === "") {
                this.popoverTarget.hidePopover();

                const hintElement = this.hintContainerTarget.querySelector(`p[data-xpath="${this.xpath}"]`).closest("li");
                if (hintElement) {
                    this._deleteHint(hintElement);
                }

                return;
            }

            this._hints[this.xpath] ? this._updateHint() : this._saveHint();
        }
    }

    addHint(event) {
        this.popoverTarget.togglePopover();
        this.textareaTarget.focus();

        event.preventDefault();
        event.stopPropagation();

        this._currentSelection = this.selectedElement;
        this.xpath             = this._getXPath(this.selectedElement);

        this.textareaTarget.value = this._hints[this.xpath] || "";
    }

    showHint(event) {
        const eyeIcon = event.currentTarget;

        eyeIcon.classList.add("hidden");
        eyeIcon.previousElementSibling.classList.remove("hidden");

        const path    = eyeIcon.parentElement.parentElement.nextElementSibling.dataset.xpath;
        const element = this._getElementByXpath(path);

        element.style.outline       = "solid #1185E5";
        element.style.outlineOffset = "0.25rem";
    }

    hideHint(event) {
        const eyeIcon = event.currentTarget;

        eyeIcon.classList.add("hidden");
        eyeIcon.nextElementSibling.classList.remove("hidden");

        const path    = eyeIcon.parentElement.parentElement.nextElementSibling.dataset.xpath;
        const element = this._getElementByXpath(path);

        element.style.outline       = "";
        element.style.outlineOffset = "";
    }

    deleteHint(event) {
        const hint = event.currentTarget.closest("li");
        this._deleteHint(hint);
    }

    deleteAllHints() {
        this.hintContainerTarget.innerHTML = "";
        this._hints                        = {};
        this.countTarget.innerHTML         = 0;
        this.blankTarget.classList.remove("hidden");
    }

    showPreview() {
        const content        = this.htmlTarget.innerText.toString("ascii");
        const decoded        = decodeURIComponent(escape(window.atob(content)));
        const iFrame         = this.iframeTarget.contentWindow.document.querySelector("html");
        iFrame.style.padding = "10px 10px 0px 10px";

        const subject    = this.htmlTarget.dataset.subject;
        const sender     = this.htmlTarget.dataset.sender;
        iFrame.innerHTML = insertSubjectSender(decoded, subject, sender);
    }

    _sendForm() {
        if (Object.keys(this._hints).length === 0) {
            alert("Please add at least one hint to proceed.");
            return;
        }

        const form  = document.createElement("form");
        form.method = "POST";
        form.action = "/admin/threat-simulations";

        const method = document.createElement("input");
        method.type  = "hidden";
        method.name  = "_method";
        method.value = "POST";
        form.appendChild(method);

        const input = document.createElement("input");
        input.type  = "hidden";
        input.name  = "Hints";
        input.value = JSON.stringify(this._hints);

        const templateInput = document.createElement("input");
        templateInput.type  = "hidden";
        templateInput.name  = "TemplateID";
        templateInput.value = this.templateSelectTarget.value;

        form.appendChild(templateInput);

        const csrf = document.createElement("input");
        csrf.type  = "hidden";
        csrf.name  = document.querySelector(`[name="csrf-param"]`).content;
        csrf.value = document.querySelector(`[name="csrf-token"]`).content;
        form.appendChild(csrf);

        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
    }

    _saveHint() {
        this.popoverTarget.hidePopover();
        this._hints[this.xpath] = hint.value;

        const hintContainer = this.hintContainerTarget;

        // Create and append the new hint element
        const hintElement = this.hintTemplateTarget.content.cloneNode(true);

        // Set the hint value and xpath and append it to the hint container.
        const p         = hintElement.querySelector("p");
        p.innerText     = hint.value;
        p.dataset.xpath = this.xpath;

        hintContainer.appendChild(hintElement);

        // Update the hint count display
        this.countTarget.innerHTML = hintContainer.children.length;

        // Reset the input field
        this.textareaTarget.value = "";

        // Show the blank state if there are no hints
        const hintCount = hintContainer.children.length;
        hintCount === 0 ? this.blankTarget.classList.remove("hidden") : this.blankTarget.classList.add("hidden");

        // highlight the selected element
        this._currentSelection.style.outline       = "solid #1185E5";
        this._currentSelection.style.outlineOffset = "0.25rem";
        this._currentSelection.dataset.hint        = true;
    }

    _updateHint() {
        this._hints[this.xpath] = hint.value;

        const hintElement         = this.hintContainerTarget.querySelector(`p[data-xpath="${this.xpath}"]`);
        hintElement.innerText     = hint.value;
        this.textareaTarget.value = "";
        this.popoverTarget.hidePopover();
    }

    _deleteHint(hint) {
        const xpath         = hint.querySelector("p").dataset.xpath;
        const hintContainer = this.hintContainerTarget;

        hint.remove();

        // Show the blank state if there are no hints
        const hintCount = hintContainer.children.length;
        hintCount === 0 ? this.blankTarget.classList.remove("hidden") : this.blankTarget.classList.add("hidden");

        // Remove the hint from the hints object
        delete this._hints[xpath];

        // Update the hint count display
        this.countTarget.innerHTML = hintContainer.children.length;

        // Reset the hint element outline
        const element               = this._getElementByXpath(xpath);
        element.style.outline       = "";
        element.style.outlineOffset = "";
        element.removeAttribute("data-hint");
    }

    _getXPath(element) {
        let selector       = "";
        let currentElement = element;

        // Loop until we reach the HTML element
        while (currentElement && currentElement.tagName.toLowerCase() !== "html") {
            const tagName       = currentElement.tagName.toLowerCase();
            const parentElement = currentElement.parentElement;
            const siblings      = [...parentElement.children]; // Convert child nodes to an array

            // Check if there are siblings with the same tag name
            const sameTagSiblings = siblings.filter(child => child.tagName.toLowerCase() === tagName);

            // If it's the only one of its type, just use the tag name
            if (sameTagSiblings.length === 1) {
                selector = `/${tagName}${selector}`;
            } else {
                // Otherwise, use the index of the element in the parent's children
                const position = sameTagSiblings.indexOf(currentElement) + 1;
                selector       = `/${tagName}[${position}]${selector}`;
            }

            // Move to the parent element for the next iteration
            currentElement = parentElement;
        }

        // Append the HTML root to the selector if we've reached the HTML element
        if (currentElement && currentElement.tagName.toLowerCase() === "html") {
            selector = `/html${selector}`;
        }

        return selector;
    }

    _getElementByXpath(path) {
        const iframeDoc = this.iframeTarget.contentWindow.document;

        return iframeDoc.evaluate(path, iframeDoc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    }
}
