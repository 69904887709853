import {Controller} from "@hotwired/stimulus";
import * as htmx from "htmx.org";
import insertSubjectSender from "./insert_subject_sender";

// The user is taking the assignment in "live"
const threatSimulationLessonMode    = "COURSE";
// The user sees the threat simulation without the need of taking any interaction.
const threatSimulationPrecourseMode = "PRECOURSE";
// The user has already completed the assignment and is coming back to review the material.
const threatSimulationReviewMode    = "REVIEW";
// Mode for browsing the catalog and wants to “preview” the template.
const threatSimulationPreviewMode   = "PREVIEW";

export default class extends Controller {
    static targets = ["iframe", "hintFoundTemplate", "hintMissingTemplate", "hintPreviewTemplate"];
    static values  = {
        assignment: String,
        html: String,
        mode: String,
        sender: String,
        subject: String,
        answers: Object,
        hints: Object,
    };

    connect() {
        this._answers = this.answersValue || {};
        this._hints   = this.hintsValue || {};
        this.element.removeAttribute("data-threatsimulations--assignment-hints-value");
        this._clickHandlers = new Map();

        this.renderPreview();
        this.setupModeBehavior();

        if (this.modeValue !== threatSimulationLessonMode) return;

        this.attachHints();
        const btn = document.querySelector("#submitBtn");
        btn.addEventListener("click", () => this.handleSubmit());
    }

    renderPreview() {
        const decodedHtml = atob(this.htmlValue);
        const iframeDoc   = this.iframeTarget.contentWindow.document;

        iframeDoc.body.innerHTML = insertSubjectSender(decodedHtml, this.subjectValue, this.senderValue);
        this.element.removeAttribute("data-threatsimulations--assignment-html-value");

        if (Object.keys(this._answers).length < 0) {
            return;
        }

        if (this.modeValue === threatSimulationPreviewMode) {
            this.showAllHints();
            return;
        }

        this.showMissingHints();
        this.showFoundHints();
    }

    setupModeBehavior() {
        const iframeDocument = this.iframeTarget.contentWindow.document;
        const elements       = iframeDocument.querySelectorAll("*");

        switch (this.modeValue) {
            case threatSimulationPrecourseMode:
                this.disableInteractions(elements);
                break;
            case threatSimulationLessonMode:
                this.configureCourseMode(elements);
                break;
            case threatSimulationReviewMode:
            case threatSimulationPreviewMode:
                break;
            default:
                console.warn(`Unknown mode: ${this.modeValue}`);
        }
    }

    disableInteractions(elements) {
        elements.forEach((element) => {
            Object.assign(element.style, {
                pointerEvents: "none",
                cursor: "default",
                userSelect: "none",
            });
        });
    }

    configureCourseMode(elements) {
        elements.forEach((element) => {
            const igs = {pointerEvents: "none", cursor: "default", userSelect: "none"};
            const ds  = {cursor: "pointer"};

            const styles = element.tagName === "IMG" ? igs : ds;

            Object.assign(element.style, styles);
        });
    }

    attachHints() {
        Object.entries(this._hints).forEach(([xpath, hintText]) => {
            const element        = this._getElementByXpath(xpath);
            this._answers[xpath] = false;

            if (!element) return;

            const clickHandler = () => this._clickHandler(element, hintText, xpath);
            this._clickHandlers.set(element, clickHandler);

            element.addEventListener("click", clickHandler, {once: true});
        });
    }

    _clickHandler(element, hintText, xpath) {
        this.highlightElement(element, "found");
        this.addHint(element, hintText, "found");

        this._answers[xpath] = true;
    }

    highlightElement(element, type) {
        element.style.position = "relative";

        const highlightOverlay = document.createElement("div");

        const gradients = {
            found: "linear-gradient(to right, rgba(39, 221, 54, 0.4), rgba(39, 221, 54, 0.4), rgba(0, 225, 0, 0.4))",
            missing: "linear-gradient(to right, rgba(225, 212, 0, 0.4), rgba(225, 212, 0, 0.4), rgba(225, 212, 0, 0.4))",
        };

        highlightOverlay.style.position        = "absolute";
        highlightOverlay.style.top             = "0";
        highlightOverlay.style.left            = "0";
        highlightOverlay.style.width           = "100%";
        highlightOverlay.style.height          = "100%";
        highlightOverlay.style.backgroundImage = gradients[type];
        highlightOverlay.style.borderRadius    = "0.8em 0.3em";
        highlightOverlay.style.zIndex          = "999";
        highlightOverlay.style.pointerEvents   = "none";

        element.style.overflow = "visible";
        element.appendChild(highlightOverlay);
    }

    addHint(element, hintText, type) {
        const template = type === "found" ? this.hintFoundTemplateTarget : type === "preview" ? this.hintPreviewTemplateTarget : this.hintMissingTemplateTarget;

        const hintTemplate                              = template.content.cloneNode(true);
        hintTemplate.querySelector("#text").textContent = hintText;

        const position    = this._getRelativePosition(element, this.element);
        const hintElement = hintTemplate.firstElementChild;

        Object.assign(hintElement.style, {
            top: `${position.top}px`,
            left: `${position.left}px`,
        });

        const updatePosition = () => {
            const position = this._getRelativePosition(element, this.element);
            Object.assign(hintElement.style, {
                top: `${position.top}px`,
                left: `${position.left}px`,
            });
        };

        this.element.appendChild(hintElement);

        // Recalculate position on window resize or layout changes
        const resizeObserver = new ResizeObserver(updatePosition);
        resizeObserver.observe(this.element);

        const cleanUp = () => {
            element.removeEventListener("mouseover", mouseOverHandler);
            element.removeEventListener("mouseleave", mouseOutHandler);
            hintElement.removeEventListener("mouseover", mouseOverHandler);
            hintElement.removeEventListener("mouseleave", mouseOutHandler);
        };

        const mouseOverHandler = (event) => {
            if (event.currentTarget === element || event.currentTarget === hintElement) {
                this.showHint(hintElement);
            }
        };

        const mouseOutHandler = (event) => {
            if (event.currentTarget === element || event.currentTarget === hintElement) {
                this.hideHint(hintElement);
            }
        };

        element.addEventListener("mouseover", mouseOverHandler);
        element.addEventListener("mouseleave", mouseOutHandler);
        hintElement.addEventListener("mouseover", mouseOverHandler);
        hintElement.addEventListener("mouseleave", mouseOutHandler);

        // Initial state
        if (type === "found" && this.modeValue === threatSimulationLessonMode) {
            setTimeout(() => {
                this.hideHint(hintElement);
            }, 3000);
        } else {
            this.hideHint(hintElement);
        }

        return {hintElement, cleanUp};
    }

    showHint(hint) {
        hint.style.display    = "block";
        hint.style.transition = "opacity 1s";
        hint.style.opacity    = 1;

        hint.classList.add("hint--visible");
    }

    hideHint(hint) {
        hint.style.transition = "opacity 1s";
        hint.style.opacity    = 0;
        hint.classList.remove("hint--visible");

        setTimeout(() => {
            if (!hint.classList.contains("hint--visible")) {
                hint.style.display = "none";
            }
        }, 500);
    }

    handleSubmit() {
        this.showMissingHints();

        // Remove the click handlers to avoid setting a hint as found after the user has submitted.
        this._clickHandlers.forEach((handler, element) => {
            element.removeEventListener("click", handler);
        });
        this._clickHandlers.clear();

        this._sendScore();
    }

    showMissingHints() {
        for (const [xpath, answered] of Object.entries(this._answers)) {
            const element = this._getElementByXpath(xpath);
            if (!answered) {
                const hintText = this._hints[xpath];

                this.highlightElement(element, "missing");
                this.addHint(element, hintText, "missing");
            }
        }
    }

    showFoundHints() {
        for (const [xpath, answered] of Object.entries(this._answers)) {
            const element = this._getElementByXpath(xpath);
            if (answered) {
                const hintText = this._hints[xpath];

                this.highlightElement(element, "found");
                this.addHint(element, hintText, "found");
            }
        }
    }

    showAllHints() {
        for (const [xpath, text] of Object.entries(this._hints)) {
            const element = this._getElementByXpath(xpath);
            this.highlightElement(element, "missing");
            this.addHint(element, text, "preview");
        }
    }

    async _sendScore() {
        const assignmentId = this.assignmentValue;

        const answers = {
            "Answers": JSON.stringify(this._answers),
        };

        const score = await fetch(`/my-assignments/threat-simulations/${assignmentId}/score`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(answers),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            }).catch((error) => {
                console.error("Error submitting score", error);
            });

        const data = {
            score: score.Points,
            maxscore: 100,
            status: "completed",
        };

        await fetch(`/assignments/${assignmentId}/submit/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(data),
        }).then((response) => {
            if (!response.ok) {
                throw response;
            }

            this.showScore(score);

            console.info("Score submitted successfully");
        }).catch((error) => {
            console.error("Error submitting score", error);
        });
    }

    showScore(data) {
        const assignmentId = this.assignmentValue;

        htmx.ajax("PUT", `/my-assignments/threat-simulations/${assignmentId}/score`, {
            target: "body",
            values: {
                "Answers": data.Answers,
                "Points": data.Points,
                "Correct": data.Correct,
                "HintsCount": data.HintsCount,
            },
            swap: "beforeend",
        }).then(() => {
            const btn = document.querySelector("#confirmScore");
            btn.addEventListener("click", () => {
                htmx.ajax("GET", "/my-assignments/threat-simulations/completed-banner", {
                    target: "#tsBanner",
                    values: {
                        "value": data.Points,
                        "correctAnswers": data.Correct,
                        "totalHints": data.HintsCount,
                    },
                    swap: "outerHTML",
                });
            });
        });
    }

    _getRelativePosition(targetElement, referenceElement) {
        const targetRect    = targetElement.getBoundingClientRect();
        const referenceRect = referenceElement.getBoundingClientRect();

        // If target is inside an iframe, adjust for iframe's position in the main document
        const iframeRect = this.iframeTarget.getBoundingClientRect();

        return {
            top: targetRect.top + iframeRect.top - referenceRect.top,
            left: targetRect.left + iframeRect.left - referenceRect.left,
        };
    }

    _getElementByXpath(xpath) {
        const iframeDoc = this.iframeTarget.contentWindow.document;
        return iframeDoc.evaluate(xpath, iframeDoc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    }
}
