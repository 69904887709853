import {Controller} from "@hotwired/stimulus";
import Toastify from "toastify-js";

export default class extends Controller {
    static values = {
        message: String,
        key: String,
    };

    connect() {
        this.showToast();
    }

    showToast() {
        Toastify({
            text: this.messageValue,
            duration: 5000, // time in miliseconds
            close: false, // wether the close button should appear.
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            escapeMarkup: false,
            className: "cursor-default pointer-events-auto w-full max-w-sm min-w-[450px] overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black/5 pl-11 !py-4 text-sm font-normal text-gray-900 bg-no-repeat bg- bg-success-circle",
            style: {
                backgroundPositionY: "center",
                backgroundPositionX: "16px",
            },
            offset: {
                y: 65, // The vertical axis - can be a number or a string indicating unity. eg: '2em'
            },
        }).showToast();
    }
}
