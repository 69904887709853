import {Controller} from "@hotwired/stimulus";
import {Chart, registerables} from "chart.js";

Chart.register(...registerables);

export default class extends Controller {
    static targets = ["chart"];
    static values  = {
        url: String,
        array: Array,
        object: Object,
        type: String,
    };

    connect() {
        switch (this.typeValue) {
            case "line":
                this.lineChart();
                break;
            case "doughnut":
                this.doughnut();
                break;
            default:
                console.warn("no chart type selected");
        }
    }

    lineChart() {
        const formatter = new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "short",
            maximumFractionDigits: 1,
        });

        const data = {
            datasets: [{
                data: this.arrayValue,
                borderColor: "rgba(252, 186, 77)",
                backgroundColor: "rgba(252, 186, 77, 0.5)",
            }],
        };

        const config = {
            type: "line",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: true,
                        intersect: true,
                    },
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        border: {
                            width: 2,
                            color: "#CAD3E1",
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {

                        border: {
                            width: 2,
                            color: "#CAD3E1",
                        },
                        grid: {display: false},
                        ticks: {
                            callback: function (value) {
                                return value === 0 ? 0 : formatter.format(value);
                            },
                            padding: 10,
                        },
                    },
                },
            },
        };

        this.chart = new Chart(this.chartTarget, config);
    }

    doughnut() {
        const labels  = [];
        const rawData = [];
        let hasData   = false;

        for (const [key, value] of Object.entries(this.objectValue)) {
            labels.push(key);
            rawData.push(value);

            if (value > 0) {
                hasData = true;
            }
        }

        const data = {
            labels: labels,
            datasets: [{
                label: "Assignments",
                data: rawData,
                backgroundColor: [
                    "#1185E5",
                    "#56CE63",
                    "#FCBA4D",
                ],
                hoverOffset: 4,
                fillColor: "rgba(14,72,100,1)",
            }],
        };

        const drawTitle = {
            id: "chartTitle",
            beforeDatasetsDraw: (chart, args, options) => {
                const {ctx, data} = chart;

                const x = chart.getDatasetMeta(0).data[0].x;
                const y = chart.getDatasetMeta(0).data[0].y;

                // Total assignments
                const total = data.datasets[0].data.reduce((a, b) => a + b, 0);

                ctx.save();
                ctx.textAlign    = "center";
                ctx.textBaseline = "middle";
                ctx.font         = "bold 24px Montserrat";
                ctx.fillStyle    = "#344054";
                ctx.fillText(new Intl.NumberFormat().format(total), x, y - 10);

                // Total assignments text
                ctx.font         = "normal 12px Montserrat";
                ctx.textBaseline = "top";
                ctx.fillText("Total", x, y + 5);

                ctx.restore();
            },
        };

        const emptyState = {
            id: "emptyDoughnut",
            afterDraw(chart, args, options) {
                const {color, width, radiusDecrease} = options;

                if (!hasData) {
                    const {chartArea: {left, top, right, bottom}, ctx} = chart;
                    const centerX                                      = (left + right) / 2;
                    const centerY                                      = (top + bottom) / 2;
                    const r                                            = Math.min(right - left, bottom - top) / 2;

                    ctx.beginPath();
                    ctx.lineWidth   = width || 2;
                    ctx.strokeStyle = color || "rgb(206, 217, 230, 0.5)";
                    ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
                    ctx.stroke();
                }
            },
        };

        const config = {
            type: "doughnut",
            data: data,
            options: {
                cutout: "85%",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: true,
                        intersect: true,
                    },
                    legend: {
                        display: false,
                    },
                    emptyDoughnut: {
                        width: 18,
                        radiusDecrease: 10,
                    },
                },
            },
            plugins: [drawTitle, emptyState],
        };

        this.chart = new Chart(this.chartTarget, config);
    }

    restoreWidth() {
        this.chart.canvas.style.width = "256px";
        this.chart.update();
    }

    disconnect() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
}
