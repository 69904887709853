import { Controller } from "@hotwired/stimulus";
import { computePosition } from "@floating-ui/dom";

export default class extends Controller {
	static values = {text: String};

	get tooltip() {
		return this._tooltip;
	}

	set tooltip(tooltip) {
		this._tooltip = tooltip;
	}

	connect() {
		this.element[this.identifier] = this;

		const tooltip = document.createElement("div");
		tooltip.classList.add("tw-tooltip", "fade", "show");

		const inner = document.createElement("div");
		inner.classList.add("tooltip-inner");
		inner.innerHTML = this.textValue;

		const arrow = document.createElement("div");
		arrow.classList.add("arrow", "left-1/2");
		arrow.style.transform     = "translate(-50%, 0%)";
		arrow.dataset.popperArrow = "";

		tooltip.append(arrow, inner);
		this.tooltip = tooltip;
	}

	hide() {
		this._tooltip.remove();
	}

	disconnect() {
		this.hide();
	}

	show() {
		document.body.appendChild(this.tooltip);
		computePosition(this.element, this.tooltip, {
			placement: "top",
		}).then(({x, y}) => {
			Object.assign(this.tooltip.style, {
				left: `${x}px`,
				top: `${y}px`,
				position: "absolute",
			});
		});
	}
}
