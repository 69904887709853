/**
 * Prepends to the <body> of the Phishing Simulation template
 * a new pair of elements that will work as helpers to hint a Threat Simulation.
 * @param {HTMLElement} tmplHTML - The phishing simulation HTML.
 * @param {String} subject - The subject of the phishing simulation template.
 * @param {String} sender - The sender of the phishing simulation template.
 * @return {String} The final HTML template.
 */
function insertSubjectSender(tmplHTML, subject, sender) {
    // Get the full <body> tag
    let bodyTagRegex = /<body[^>]*>/;
    let bodyMatch    = tmplHTML.match(bodyTagRegex);
    let bodyTag      = bodyMatch[0];

    // Find the position where the <body> tag ends
    let bodyEndIndex = bodyMatch.index + bodyTag.length;

    // Insert the new HTML at the calculated position
    return tmplHTML.slice(0, bodyEndIndex) +
        createElements(subject, sender) +
        tmplHTML.slice(bodyEndIndex);
}

export default insertSubjectSender;

function createElements(subject, sender) {
    return `<div style='padding-bottom: 16px; font-family: "Montserrat", sans-serif; border-bottom: 1px solid rgba(0, 0, 0, 0.1);'>
    <div style='display: flex; align-items: center'>
        <span style='line-height: 22px; font-size: 18px; margin: 0'><b style='font-weight: 700;'>Subject:</b></span>
        <span style='font-weight: 400; margin-left: 10px'>${subject}</span>
    </div>
    <div style='display: flex; align-items: center; margin-top: 10px'>
        <span><b style='font-weight: 700; font-size: 0.875rem; line-height: 1.25rem;'>From:</b></span>
        <span style='font-weight: 400; font-size: 0.875rem; line-height: 1.25rem; margin-left: 10px'>${sender}</span>
    </div>
</div>`;
}
